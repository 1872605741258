// external dependencies
import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import styled from 'styled-components';

// gatsby dependencies
import { graphql } from 'gatsby';

// local dependencies
import Hero from '../components/shared/Hero';
import Cta from '../components/shared/Cta';
import SEO from '../components/SEO';
import Section from '../components/shared/Section';

const StyledListWrapper = styled.div`
  max-width: 800px;
  margin: auto;
  div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 2rem auto;
  }

  p {
    margin: 0 0 0 1rem;
  }

  h5 {
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gold};
    margin-bottom: 1rem;
  }
`;

// component definition
const TextPageTemplate = ({ data }) => {
  const generalList =
    data.page.general &&
    data.page.general.listItems.map((item, i) => (
      <div key={i}>
        <CheckCircleIcon htmlColor='#004e2e' />
        <p>{item.description.description}</p>
      </div>
    ));

  const featureList =
    data.page.features &&
    data.page.features.listItems.map((item, i) => (
      <div key={i}>
        <CheckCircleIcon htmlColor='#004e2e' />
        <p>{item.description.description}</p>
      </div>
    ));

    const StyledPdfWrapper = styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 950px;
      padding: 25px 50px;
      margin: 75px auto;
      background-color: ${props => props.theme.colors.ecru};
      border-radius: 5px;
      box-shadow: 0px 0px 20px rgb(0 0 0 / 20%);

      img {
        width: 200px;
        padding: 15px;
      }

      h3 {
        margin-bottom: 25px;
      }

      a {
        padding: 8px 16px;
        border-radius: 4px;
        border: none;
        background-color: ${props => props.theme.colors.gold};
        color: white;

        &:hover {
          filter: brightness(95%);
        }
      }

      @media(max-width: 600px) {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        padding: 25px 50px 50px;
      }
    `

  const pdfDownloadSection = data.page.pdfDownload && (
    <StyledPdfWrapper>
    <img src={data.page.pdfDownload.previewImage.file.url} />
    <div>
    <h3>{data.page.pdfDownload.headline}</h3>
    <a href={data.page.pdfDownload.pdf.file.url} target='_blank'>Download Report</a>
    </div>

    </StyledPdfWrapper>
  );

  return (
    <>
      <SEO
        title={data.page.pageTitle}
        description={data.page.metaDescription}
      />
      <Hero
        superText={data.page.hero.supertext}
        headline={data.page.hero.headline}
        short
      />
      <Section>
        {/* <p style={{ textAlign: 'center', marginBottom: '50px' }}>
          {data.page.description.description}
        </p> */}
        {data.page.general && (
          <StyledListWrapper>
            <h5>{data.page.general.entryTitle}</h5>
            {generalList}
          </StyledListWrapper>
        )}
        {data.page.features && (
          <StyledListWrapper style={{ marginTop: '4rem' }}>
            <h5>{data.page.features.entryTitle}</h5>
            {featureList}
          </StyledListWrapper>
        )}
        {data.page.pdfDownload && pdfDownloadSection}
      </Section>
      <Cta
        ctaSupertext={data.page.ctaSection.supertext}
        ctaHeadline={data.page.ctaSection.headline}
        phoneNumber={data.page.ctaSection.phoneNumber}
        email={data.page.ctaSection.email}
        ctaImage={data.page.ctaSection.image}
      />
    </>
  );
};

export const query = graphql`
  query($slug: String!) {
    page: contentfulProductInformationPageTemplate(slug: { eq: $slug }) {
      ctaSection {
        email
        headline
        phoneNumber
        supertext
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      description {
        description
      }
      metaDescription
      pageTitle
      features {
        entryTitle
        id
        listItems {
          entryTitle
          description {
            description
          }
        }
      }
      general {
        entryTitle
        listItems {
          description {
            description
          }
          entryTitle
        }
      }
      hero {
        headline
        supertext
        buttonLink
        buttonText
      }
      pdfDownload {
        headline
        previewImage {
          file {
            url
          }
        }
        pdf {
          file {
            url
          }
        }
      }
    }
  }
`;

export default TextPageTemplate;
